<template>
    <div class="login-container">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
            <div class="title-container">
                <h3 class="title">登录</h3>
            </div>

            <el-form-item prop="username">
                <el-input ref="username" v-model="loginForm.username" placeholder="用户名" name="username" type="text" tabindex="1" auto-complete="on" />
            </el-form-item>

            <el-form-item prop="password">
                <el-input ref="password" v-model="loginForm.password" placeholder="密码" name="password" type="password" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
            </el-form-item>

            <el-button :loading="loading" type="primary" style="width: 100%; margin-bottom: 30px" @click.native.prevent="handleLogin">登录</el-button>
        </el-form>
    </div>
</template>

<script>
import { login } from '@/api/user';
import { mapActions } from 'vuex';

export default {
    name: 'Login',
    data() {
        return {
            loginForm: {
                username: '',
                password: '',
            },
            loginRules: {
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
            loading: false,
        };
    },
    methods: {
        ...mapActions('user', ['setLoginData']),
        handleLogin() {
            this.$refs.loginForm.validate(async valid => {
                if (valid) {
                    this.loading = true;
                    try {
                        const response = await login(this.loginForm);
                        if (response.status === 200) {
                            // 保存用户信息到 store
                            await this.setLoginData(response.data);
                            this.$message.success('登录成功');

                            // 使用 replace 而不是 push，避免浏览器历史记录问题
                            const redirect = this.$route.query.redirect || '/dashboard';
                            this.$router.replace(redirect);
                        }
                    } catch (error) {
                        console.error('登录失败:', error);
                        this.$message.error(error.message || '登录失败');
                    } finally {
                        this.loading = false;
                    }
                }
            });
        },
    },
};
</script>

<style>
.login-container {
    min-height: 100%;
    width: 100%;
    background-color: #2d3a4b;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.title-container {
    position: relative;
}
.title {
    font-size: 26px;
    color: #333;
    margin: 0 auto 40px auto;
    text-align: center;
    font-weight: bold;
}
</style>
