<template>
    <div class="dashboard-container">
        <div class="dashboard-header">
            <div class="header-title">{{ isAdmin ? '管理员' : '用户' }}仪表盘</div>
            <div class="header-right">
                <el-button type="primary" @click="handleCreate">创建任务</el-button>
                <div class="welcome-text">欢迎，{{ nickname }}！</div>
                <el-button type="primary" @click="handleLogout">退出登录</el-button>
            </div>
        </div>

        <div class="search-area">
            <el-select v-model="searchType" placeholder="搜索类型" class="search-type">
                <el-option label="任务ID" value="taskId"></el-option>
            </el-select>
            <el-input v-model="searchQuery" placeholder="请输入任务ID" class="search-input" clearable @clear="handleSearch">
                <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
            </el-input>
        </div>

        <el-table :data="paginatedData" style="width: 100%">
            <el-table-column prop="taskId" label="任务ID" width="120"></el-table-column>
            <el-table-column prop="account" label="账号" width="120"></el-table-column>
            <el-table-column prop="password" label="密码" width="120"></el-table-column>
            <el-table-column prop="authority" label="令牌" width="120"></el-table-column>
            <el-table-column prop="created_at" label="提交时间" width="180"></el-table-column>
            <el-table-column prop="status" label="状态" width="120">
                <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.status === '失败'" :content="scope.row.failureReason || '无失败原因'" placement="top" effect="dark">
                        <el-tag :type="getStatusType(scope.row.status)" class="status-tag">
                            {{ getStatusText(scope.row.status) }}
                        </el-tag>
                    </el-tooltip>
                    <el-tag v-else :type="getStatusType(scope.row.status)" class="status-tag">
                        {{ getStatusText(scope.row.status) }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" min-width="200">
                <template slot-scope="scope">
                    <span v-if="!scope.row.isEditingRemark" @click="handleRemarkEdit(scope.row)">
                        {{ scope.row.remark || '无备注' }}
                    </span>
                    <div v-else class="remark-edit">
                        <el-input v-model="scope.row.remark" size="small" @blur="handleRemarkSave(scope.row)" @keyup.enter.native="handleRemarkSave(scope.row)"></el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="250" fixed="right">
                <template slot-scope="scope">
                    <template v-if="isAdmin">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleRetry(scope.row)"
                            :disabled="scope.row.status === 'success'"
                            :title="scope.row.status === 'success' ? '已完成的任务无需重试' : ''"
                        >
                            重试
                        </el-button>
                        <el-button size="mini" type="warning" @click="handleAudit(scope.row)">审计</el-button>
                    </template>
                    <template v-else>
                        <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>

        <!-- 编辑对话框 -->
        <el-dialog :title="editingTask ? '编辑任务' : '新建任务'" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="500px">
            <el-form :model="editForm" label-width="100px" ref="editForm" :rules="editRules">
                <el-form-item label="账号" prop="account">
                    <el-input v-model="editForm.accountInfo.account"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="editForm.accountInfo.password"></el-input>
                </el-form-item>
                <el-form-item label="令牌">
                    <el-input v-model="editForm.accountInfo.authority"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="editForm.remark" :rows="3" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleEditSubmit">确定</el-button>
            </div>
        </el-dialog>

        <!-- 添加审计对话框 -->
        <audit-dialog :visible.sync="auditDialogVisible" :task-data="currentAuditTask" @audit-submitted="handleAuditSubmitted"></audit-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTaskList, createTask, updateTask, deleteTask } from '@/api/task';
import { setLoggingOut } from '@/router';
import AuditDialog from '@/components/AuditDialog.vue';

export default {
    name: 'UserDashboard',
    components: {
        AuditDialog,
    },
    computed: {
        ...mapGetters('user', ['nickname', 'isAdmin']),
        paginatedData() {
            return this.taskData;
        },
    },
    data() {
        return {
            loading: false,
            searchType: 'taskId',
            searchQuery: '',
            taskData: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            editDialogVisible: false,
            editingTask: null,
            editForm: {
                taskId: '',
                remark: '',
                accountInfo: {
                    account: '',
                    password: '',
                    authority: '',
                },
            },
            editRules: {
                'accountInfo.account': [{ required: true, message: '请输入账号', trigger: 'blur' }],
                'accountInfo.password': [{ required: true, message: '请输入密码', trigger: 'blur' }],
            },
            auditDialogVisible: false,
            currentAuditTask: null,
        };
    },
    methods: {
        getStatusType(status) {
            const statusMap = {
                等待中: 'info',
                进行中: 'warning',
                完成: 'success',
                失败: 'danger',
            };
            return statusMap[status] || 'info';
        },
        getStatusText(status) {
            const statusTextMap = {
                等待中: '等待',
                进行中: '进行',
                完成: '完成',
                失败: '失败',
            };
            return statusTextMap[status] || status;
        },
        async loadTaskData() {
            this.loading = true;
            try {
                const params = {
                    page: this.currentPage,
                    pageSize: this.pageSize,
                };

                if (this.searchQuery) {
                    params.taskId = this.searchQuery;
                }

                const response = await getTaskList(params);
                this.taskData = response.data.items;
                this.total = response.data.total;
            } catch (error) {
                console.error('加载任务数据失败:', error);
                this.$message.error('加载任务数据失败');
            } finally {
                this.loading = false;
            }
        },
        handleCreate() {
            this.editingTask = null;
            this.editForm = {
                taskId: '',
                remark: '',
                accountInfo: {
                    account: '',
                    password: '',
                    authority: '',
                },
            };
            this.editDialogVisible = true;
        },
        handleEdit(row) {
            this.editingTask = row;
            this.editForm = {
                taskId: row.taskId,
                remark: row.notes,
                accountInfo: {
                    account: row.account,
                    password: row.password,
                    authority: row.authority,
                },
            };
            this.editDialogVisible = true;
        },
        async handleEditSubmit() {
            this.$refs.editForm.validate(async valid => {
                if (valid) {
                    try {
                        if (this.editingTask) {
                            // 编辑任务
                            const response = await updateTask({
                                remark: this.editForm.remark,
                                ...this.editForm.accountInfo,
                                taskId: this.editForm.taskId,
                            });

                            if (response.statue === 200) {
                                this.$message.success('更新成功');
                                this.editDialogVisible = false;
                                this.loadTaskData();
                            } else {
                                this.$message.error('更新失败');
                            }
                        } else {
                            // 创建任务
                            const taskData = {
                                account: this.editForm.accountInfo.account,
                                password: this.editForm.accountInfo.password,
                                authority: this.editForm.accountInfo.authority,
                                notes: this.editForm.remark,
                            };
                            const response = await createTask(taskData);
                            if (response.status === 200) {
                                this.$message.success('创建成功');
                                this.editDialogVisible = false;
                                this.loadTaskData();
                            } else {
                                this.$message.error('创建失败');
                            }
                        }
                    } catch (error) {
                        console.error(this.editingTask ? '更新失败:' : '创建失败:', error);
                        this.$message.error(this.editingTask ? '更新失败' : '创建失败');
                    }
                }
            });
        },
        async handleDelete(row) {
            try {
                await this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                });

                await deleteTask(row.taskId);
                this.$message.success('删除成功');
                this.loadTaskData();
            } catch (error) {
                if (error !== 'cancel') {
                    console.error('删除失败:', error);
                    this.$message.error('删除失败');
                }
            }
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.loadTaskData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.loadTaskData();
        },
        handleSearch() {
            this.currentPage = 1;
            this.loadTaskData();
        },
        async handleLogout() {
            try {
                setLoggingOut(true);
                await this.$store.dispatch('user/logout');
                this.$router.push('/login');
            } catch (error) {
                console.error('退出登录失败:', error);
                this.$message.error('退出登录失败');
            }
        },
        handleRemarkEdit(row) {
            this.$set(row, 'isEditingRemark', true);
        },
        async handleRemarkSave(row) {
            try {
                const response = await updateTask({ remark: row.remark, taskId: row.taskId });
                if (response.status === 200) {
                    this.$message.success('备注更新成功');
                    this.$set(row, 'isEditingRemark', false);
                } else {
                    this.$message.error('备注更新失败');
                }
            } catch (error) {
                console.error('更新备注失败:', error);
                this.$message.error('更新备注失败');
            }
        },
        async handleRetry(row) {
            try {
                // TODO: 调用重试任务的 API
                await this.$confirm('确定要重试该任务吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                });

                // const response = await retryTask(row.taskId);
                this.$message.success('任务重试已提交');
                this.loadTaskData();
            } catch (error) {
                if (error !== 'cancel') {
                    console.error('重试失败:', error);
                    this.$message.error('重试失败');
                }
            }
        },

        async handleAudit(row) {
            this.currentAuditTask = row;
            this.auditDialogVisible = true;
        },

        handleAuditSubmitted() {
            this.loadTaskData(); // 刷新任务列表
        },
    },
    created() {
        this.loadTaskData();
    },
};
</script>

<style scoped>
.dashboard-container {
    padding: 20px;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-title {
    font-size: 24px;
    font-weight: bold;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.welcome-text {
    font-size: 16px;
}

.search-area {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
}

.search-type {
    width: 120px;
}

.search-input {
    width: 300px;
}

.status-tag {
    cursor: default;
}

.remark-edit {
    display: flex;
    gap: 10px;
}

.pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>
