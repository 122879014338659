<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background-color: #f3f3f3;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.dashboard-container {
    padding: 20px;
}

.pagination-container {
    margin-top: 20px;
    text-align: right;
}
</style>
