import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
 
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { allowAnonymous: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let isLoggingOut = false

// 导航守卫
router.beforeEach((to, from, next) => {
  const token = store.getters['user/token']
  const userInfo = store.getters['user/userInfo']

  // 处理登出状态
  if (isLoggingOut) {
    isLoggingOut = false
    next()
    return
  }

  // 允许匿名访问的路由
  if (to.matched.some(record => record.meta.allowAnonymous)) {
    if (token && !isLoggingOut) {
      next('/dashboard')
    } else {
      next()
    }
    return
  }

  // 需要认证的路由
  if (!token) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
    return
  }

  
  next()
})

// 导出路由实例和登出标志设置函数
export default router
export const setLoggingOut = (value) => {
  isLoggingOut = value
}