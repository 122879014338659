import request from '@/utils/request'

export function createTask(data) {
  return request({
    url: '/api/tasks/v1',
    method: 'post',
    data
  })
}

export function getTaskList(params) {
  return request({
    url: '/api/tasks/v1',
    method: 'get',
    params
  })
}

export function updateTask( data) {
  return request({
    url: `/api/tasks/update/v1`,
    method: 'post',
    data
  })
}

export function deleteTask(taskId) {
  return request({
    url: `/api/tasks/delete/v1`,
    method: 'post',
    data: {
      taskId
    }
  })
}
