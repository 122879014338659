<template>
    <el-dialog title="任务审计" :visible.sync="visible" :close-on-click-modal="false" @closed="handleClose" width="90vw" :fullscreen="false" custom-class="audit-dialog">
        <div class="audit-content">
            <el-tabs v-model="activeTab" type="border-card">
                <el-tab-pane label="基本信息" name="basic">
                    <el-form label-width="100px">
                        <el-form-item label="任务ID">
                            <span>{{ taskData?.taskId }}</span>
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <span>{{ taskData?.created_at }}</span>
                        </el-form-item>
                        <el-form-item label="当前状态">
                            <el-tag :type="getStatusType(taskData?.status)">{{ taskData?.status }}</el-tag>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane v-for="tab in tabs" :key="tab.name" :label="tab.label" :name="tab.name" >
                    <div class="items-container">
                        <div v-for="(item, key) in tab.data" :key="key" class="item-row">
                            <div class="item-header">
                                <span class="item-key">{{ key }}</span>
                                <span class="item-text">{{ item.text }}</span>
                                <span class="item-classify">{{ item.classify }}</span>
                            </div>
                            <div class="item-content">
                                <div class="source-image">
                                    <img :src="getImage(item,'crops')" alt="source" />
                                </div>
                                <div class="search-results">
                                    <div v-for="(search, index) in (item.data?.search ?? [])" :key="index" class="search-item">
                                        <img :src="getImage(search,'search')" alt="search result" />
                                        <div class="search-info">
                                            <span>类型: {{ search.type }}</span>
                                            <span>距离: {{ search.distance }}</span>
                                            <span>文本: {{ search.text }}</span>
                                            <span>分类: {{ search.classify }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="debug-image">
                                    <img :src="getImage(item,'debug')" alt="debug" />
                                </div>
                                <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleRemove(key)">移除</el-button>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </el-dialog>
</template>

<script>
import { getAuditData } from '@/api/audit';

const IMAGE_BASE_URL = 'https://down-1310193354.cos.ap-shanghai.myqcloud.com';

export default {
    name: 'AuditDialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        taskData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            activeTab: 'basic',
            auditData: {},
            auditNote: '',
        };
    },
    computed: {
        tabs() {
            if (!this.auditData?.data) {
                return [];
            }
            return [
                {
                    label: '衣服',
                    name: 'armoire',
                    data: this.auditData.data.armoire || {},
                },
                {
                    label: '外貌',
                    name: 'appearance',
                    data: this.auditData.data.appearance || {},
                },
                {
                    label: '大厅姿势',
                    name: 'posture',
                    data: this.auditData.data.posture || {},
                },
                {
                    label: '武器',
                    name: 'weapon',
                    data: this.auditData.data.weapon || {},
                },

                {
                    label: '表情动作',
                    name: 'emote',
                    data: this.auditData.data.emote || {},
                },
                {
                    label: '交通工具',
                    name: 'vehicle',
                    data: this.auditData.data.vehicle || {},
                },
                {
                    label: '大厅',
                    name: 'lobby',
                    data: this.auditData.data.lobby || {},
                },

                {
                    label: '功能性',
                    name: 'utility',
                    data: this.auditData.data.utility || {},
                },

                {
                    label: '统计',
                    name: 'statistics',
                    data: this.auditData.data.statistics || {},
                },
            ];
        },
    },
    methods: {
        getImage(item,classify){
            if(classify === 'search'){
                console.log(item)
                return `${IMAGE_BASE_URL}/store/${item.type}.jpg`
                // return `http://127.0.0.1:4321/skins/${item.skins}.png`
            }
            if(classify === 'debug'){
                return `${IMAGE_BASE_URL}/ai/${this.taskData.taskId}/${classify}/${item.crop}_debug.jpg`
            }
            return `${IMAGE_BASE_URL}/ai/${this.taskData.taskId}/${classify}/${item.data.cropspath}.png`
        },
        getStatusType(status) {
            const statusMap = {
                success: 'success',
                failed: 'danger',
                pending: 'info',
                processing: 'warning',
            };
            return statusMap[status] || 'info';
        },
        handleClose() {
            this.auditNote = '';
            this.auditData = {};
            this.$emit('update:visible', false);
        },
        async loadAuditData() {
            try {
                const response = await getAuditData(this.taskData.taskId);
                if (response.status === 200) {
                    this.auditData = response.data;
                    console.log(this.auditData);
                } else {
                    this.$message.error('加载审计数据失败');
                }
            } catch (error) {
                console.error('加载审计数据失败:', error);
                this.$message.error('加载审计数据失败');
            }
        },
        async handleRemove(key) {},
    },
    watch: {
        visible: {
            immediate: false,
            handler(newVal) {
                if (newVal && this.taskData?.taskId) {
                    this.loadAuditData();
                }
            }
        }
    },
};
</script>

<style scoped>
.audit-dialog {
    height: 80vh;
    display: flex;
    flex-direction: column;
}

.audit-content {
    height: calc(80vh - 120px);
    overflow-y: auto;
}

.items-container {
    padding: 20px;
}

.item-row {
    border: 1px solid #ebeef5;
    margin-bottom: 20px;
    border-radius: 4px;
}

.item-header {
    background-color: #f5f7fa;
    padding: 10px;
    display: flex;
    gap: 20px;
}

.item-content {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.source-image img,
.debug-image img {
    max-width: 200px;
    max-height: 200px;
}

.search-results {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.search-item {
    border: 1px solid #dcdfe6;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.search-item img {
    max-width: 150px;
    max-height: 150px;
}

.search-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #606266;
}
</style>
