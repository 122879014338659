import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || 'http://ps.aurls.de',// 'http://192.243.126.174:8888', //'http://127.0.0.1:5189', // api的base_url
  timeout: 15000, // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 从 store 中获取 token
    const token = store.getters['user/token']
    if (token) {
      // 将 token 添加到请求头
      config.headers['token'] = token
    }
    return config
  },
  error => {
    console.error('请求错误:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 这里处理 statue 拼写错误
    if (res.statue !== 200 && res.status !== 200) {
      Message({
        message: res.message || '请求失败',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || '请求失败'))
    }
    return res
  },
  error => {
    console.error('响应错误:', error)
    Message({
      message: error.message || '请求失败',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
