// 从 localStorage 获取初始状态
const getDefaultState = () => {
  return {
    token: localStorage.getItem('token') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {
      username: '',
      nickname: '',
      mail: '',
      qq: ''
    },
    indexData: localStorage.getItem('indexData') || null
  }
}

// 用户模块的初始状态
const state = getDefaultState()

// mutations 用于修改状态
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
    localStorage.setItem('token', token)
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  SET_INDEX_DATA(state, indexData) {
    state.indexData = indexData
    localStorage.setItem('indexData', indexData)
  },
  CLEAR_USER_STATE(state) {
    Object.assign(state, getDefaultState())
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('indexData')
  }
}

// getters 用于获取状态
const getters = {
  token: state => state.token,
  userInfo: state => state.userInfo,
  username: state => state.userInfo.username,
  nickname: state => state.userInfo.nickname,
  indexData: state => state.indexData,
  isAdmin:state => state.userInfo.roles.includes('admin')
}

// actions 用于处理异步操作
const actions = {
  // 登录成功后保存用户信息
  setLoginData({ commit }, { token, userinfo, indexData }) {
    commit('SET_TOKEN', token)
    commit('SET_USER_INFO', userinfo)
    commit('SET_INDEX_DATA', indexData)
  },
  // 退出登录
  logout({ commit }) {
    commit('CLEAR_USER_STATE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
